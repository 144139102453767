import React from 'react';
import '../styles/footer.scss';
import logo from '../assets/logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import ReactGA from 'react-ga';

function Footer() {
	return (
		<footer className="footer">
			<div className="container">
				<div className="footer-content">
					<div className="footer-logo">
						<img src={logo} alt="Ero's Elite Casting" />
					</div>
					<div className="footer-social">
						<a href="https://instagram.com"
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => ReactGA.event({ category: 'Footer', action: 'Click', label: 'Instagram' })}
						>
							<FontAwesomeIcon icon={faInstagram} />
						</a>						<a href="https://twitter.com"
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => ReactGA.event({ category: 'Footer', action: 'Click', label: 'Twitter' })}
						>
							<FontAwesomeIcon icon={faTwitter} />
						</a>					</div>
				</div>
				<div className="footer-bottom">
					<p>&copy; {new Date().getFullYear()} Ero's Elite Casting. All rights reserved.</p>
				</div>
			</div>
			<div className="ocean">
				<div className="wave"></div>
				<div className="wave"></div>
			</div>
		</footer>
	);
}

export default Footer;
