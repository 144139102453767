// send-email.js
import axios from 'axios';

const sendEmail = async (formData, endpoint) => {
	try {
		const deploymentUrl = 'https://www.eroselitecasting.com'; // Replace with your actual deployment URL
		const response = await axios.post(`${deploymentUrl}${endpoint}`, formData);
		//const response = await axios.post(`http://localhost:3001${endpoint}`, formData);
		console.log('Email sent successfully:', response.data);
	} catch (error) {
		console.error('Error sending email:', error);
		throw error;
	}
};

export default sendEmail;
