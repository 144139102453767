// Success.js
import React, { useEffect } from 'react';
import '../styles/success.scss';
import { products } from '../pages/Shop';

function Success() {
	useEffect(() => {
		const fetchPaymentDetails = () => {
			// Retrieve the purchased product ID from the URL query parameter
			const urlParams = new URLSearchParams(window.location.search);
			const purchasedProductId = urlParams.get('productId');
			console.log('Purchased Product ID:', purchasedProductId);

			if (purchasedProductId) {
				// Store the purchased product ID in session storage
				sessionStorage.setItem('purchasedProductId', purchasedProductId);

				// Find the purchased product based on the ID
				const purchasedProduct = products.find((product) => product.id === parseInt(purchasedProductId));
				console.log('Purchased Product:', purchasedProduct);

				if (purchasedProduct) {
					// Trigger the file download
					const link = document.createElement('a');
					link.href = purchasedProduct.file;
					link.download = purchasedProduct.name;
					link.click();
					console.log('File download triggered');
				} else {
					console.warn('Purchased product not found');
				}
			} else {
				console.warn('Purchased product ID not found in URL query parameter');
			}
		};

		fetchPaymentDetails();
	}, []);

	const handleDownload = () => {
		const purchasedProductId = sessionStorage.getItem('purchasedProductId');
		console.log('Download button clicked. Purchased Product ID:', purchasedProductId);

		if (purchasedProductId) {
			const purchasedProduct = products.find((product) => product.id === parseInt(purchasedProductId));
			console.log('Purchased Product:', purchasedProduct);

			if (purchasedProduct) {
				const link = document.createElement('a');
				link.href = purchasedProduct.file;
				link.download = purchasedProduct.name;
				link.click();
				console.log('File download triggered');
			} else {
				console.warn('Purchased product not found');
			}
		} else {
			console.warn('Purchased product ID not found in session storage');
		}
	};

	return (
		<div className="success-page">
			<h1>Payment Successful</h1>
			<p>Thank you for your purchase!</p>
			<p>Your file should start downloading automatically.</p>
			<button onClick={handleDownload}>Download File</button>
		</div>
	);
}

export default Success;
