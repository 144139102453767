// HowWeWork.js
import React from 'react';
import '../styles/howWeWork.scss';

function HowWeWork() {
	return (
		<section className="how-we-work">
			<div className="container">
				<h1>How We Work</h1>
				<div className="steps">
					<div className="step">
						<div className="step-number">1</div>
						<h3>Submit Your Project Details</h3>
						<p>Tell us about your project requirements and preferences.</p>
					</div>
					<div className="step">
						<div className="step-number">2</div>
						<h3>Talent Matching</h3>
						<p>Our experts handpick the most suitable talent for your project.</p>
					</div>
					<div className="step">
						<div className="step-number">3</div>
						<h3>Collaboration</h3>
						<p>We facilitate seamless communication and collaboration between you and the talent.</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HowWeWork;
