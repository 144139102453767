// ApplyNow.js
import React, { useState } from 'react';
import sendEmail from './api/send-email';
import '../styles/applyNow.scss';
import ReactGA from 'react-ga';

function ApplyNow() {
	const [selectedServices, setSelectedServices] = useState([]);
	const [submitStatus, setSubmitStatus] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	const handleServiceChange = (e) => {
		const service = e.target.value;
		setSelectedServices((prevServices) =>
			prevServices.includes(service)
				? prevServices.filter((s) => s !== service)
				: [...prevServices, service]
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formElements = e.target.elements;

		const formData = {
			services: selectedServices,
			name: formElements.name.value,
			phone: formElements.phone.value,
			email: formElements.email.value,
			activeOnlyFansAccount: formElements.activeOnlyFansAccount?.value || '',
			numberOfOnlyFansAccounts: formElements.numberOfOnlyFansAccounts?.value || '',
			onlyFansAccounts: formElements.onlyFansAccounts?.value || '',
			otherPlatforms: formElements.otherPlatforms?.value || '',
			paidFans: formElements.paidFans?.value || '',
			freeFans: formElements.freeFans?.value || '',
			onlyFansPercentage: formElements.onlyFansPercentage?.value || '',
			averageMonthlyEarnings: formElements.averageMonthlyEarnings?.value || '',
			guaranteedGains: formElements.guaranteedGains?.value || '',
			socialMediaFollowers: formElements.socialMediaFollowers
				? Array.from(formElements.socialMediaFollowers)
					.filter((checkbox) => checkbox.checked)
					.map((checkbox) => checkbox.value)
				: [],
			largestFollowing: formElements.largestFollowing?.value || '',
			socialMediaLink: formElements.socialMediaLink?.value || '',
			about: formElements.about?.value || '',
			howDidYouHearAboutUs: formElements.howDidYouHearAboutUs?.value || '',
			castingQuestion: formElements.castingQuestion?.value || '',
			twitterLink: formElements.twitterLink?.value || '',
			instagramLink: formElements.instagramLink?.value || '',
			facebookLink: formElements.facebookLink?.value || '',
			tiktokLink: formElements.tiktokLink?.value || '',
		};

		try {
			await sendEmail(formData, '/api/send-application-email');
			setSubmitStatus('success');
			setErrorMessage('');
			ReactGA.event({
				category: 'Apply Now Form',
				action: 'Submit',
				label: 'Application Form Submission',
			});
			// Reset form data or perform any other necessary actions
		} catch (error) {
			setSubmitStatus('error');
			setErrorMessage(error.message);
		}
	};

	return (
		<section className="apply-now">
			<div className="container">
				<h1>Apply Now</h1>
				<p className="instructions">
					Fill out the form below to apply for representation. Provide details about your OnlyFans presence, the services you're interested in, and your contact information. Our team will review your application and get back to you shortly.
				</p>
				<div className="apply-now-content">
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="services">Services Interested In:</label>
							<div className="checkbox-group">
								<label>
									<input
										type="checkbox"
										name="services"
										value="chatters"
										checked={selectedServices.includes('chatters')}
										onChange={handleServiceChange}
									/>
									Chatters
								</label>
								<label>
									<input
										type="checkbox"
										name="services"
										value="marketing"
										checked={selectedServices.includes('marketing')}
										onChange={handleServiceChange}
									/>
									Marketing
								</label>
								<label>
									<input
										type="checkbox"
										name="services"
										value="casting"
										checked={selectedServices.includes('casting')}
										onChange={handleServiceChange}
									/>
									Casting
								</label>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="name">Name:</label>
							<input type="text" id="name" name="name" required />
						</div>
						<div className="form-group">
							<label htmlFor="phone">Phone Number:</label>
							<input type="tel" id="phone" name="phone" required />
						</div>
						<div className="form-group">
							<label htmlFor="email">Email Address:</label>
							<input type="email" id="email" name="email" required />
						</div>
						{(selectedServices.includes('chatters') || selectedServices.includes('marketing')) && (
							<>
								<div className="form-group">
									<label htmlFor="activeOnlyFansAccount">Do you have an active OnlyFans account?</label>
									<div className="radio-group">
										<label>
											<input type="radio" name="activeOnlyFansAccount" value="yes" required /> Yes
										</label>
										<label>
											<input type="radio" name="activeOnlyFansAccount" value="no" required /> No
										</label>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="numberOfOnlyFansAccounts">Do you have more than one OnlyFans account?</label>
									<div className="radio-group multiple-options">
										<label>
											<input type="radio" name="numberOfOnlyFansAccounts" value="one" required /> No, I only have one OnlyFans account.
										</label>
										<label>
											<input type="radio" name="numberOfOnlyFansAccounts" value="two" required /> Yes, I have two.
										</label>
										<label>
											<input type="radio" name="numberOfOnlyFansAccounts" value="moreThanTwo" required /> Yes, I have more than two.
										</label>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="onlyFansAccounts">Please provide us with the link(s) to your OnlyFans account(s):</label>
									<input type="text" id="onlyFansAccounts" name="onlyFansAccounts" required />
								</div>
								<div className="form-group">
									<label htmlFor="otherPlatforms">Do you run exclusive pages on any other platform?</label>
									<div className="radio-group">
										<label>
											<input type="radio" name="otherPlatforms" value="onlyFans" required /> No, I only use OnlyFans.
										</label>
										<label>
											<input type="radio" name="otherPlatforms" value="majorPlatforms" required /> Yes, I use other major platforms such as Fansly, Centerfold, ManyVids, etc.
										</label>
										<label>
											<input type="radio" name="otherPlatforms" value="nichePlatforms" required /> Yes, I use other niche platforms such as Telegram, Snapchat, etc.
										</label>
										<label>
											<input type="radio" name="otherPlatforms" value="thirdParty" required /> Yes, I have a third-party site.
										</label>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="paidFans">How many paid Fans do you have on OnlyFans?</label>
									<input type="text" id="paidFans" name="paidFans" placeholder="Please give us the total number across all accounts." required />
								</div>
								<div className="form-group">
									<label htmlFor="freeFans">How many free Fans do you have on OnlyFans?</label>
									<input type="text" id="freeFans" name="freeFans" placeholder="Please give us the total number across all accounts." required />
								</div>
								<div className="form-group">
									<label htmlFor="onlyFansPercentage">What percentage are you at currently on OnlyFans?</label>
									<input type="text" id="onlyFansPercentage" name="onlyFansPercentage" placeholder="Top X.XX% -- Please include for all active OnlyFans accounts." required />
								</div>
								<div className="form-group">
									<label htmlFor="averageMonthlyEarnings">What are your average MONTHLY net earnings on OnlyFans?</label>
									<input type="text" id="averageMonthlyEarnings" name="averageMonthlyEarnings" placeholder="Please use an average calculated across all OnlyFans accounts." />
								</div>
								<div className="form-group">
									<label htmlFor="guaranteedGains">Are you currently selling Guaranteed Gains (GG) on your OnlyFans account(s)?</label>
									<div className="radio-group">
										<label>
											<input type="radio" name="guaranteedGains" value="yes" required /> Yes
										</label>
										<label>
											<input type="radio" name="guaranteedGains" value="no" required /> No
										</label>
									</div>
									<p>Please include any other type of OnlyFans based promo that requires MM, Pinned Posts, etc. on your account(s) in your answer.</p>
								</div>
								<div className="form-group">
									<label htmlFor="socialMediaFollowers">Do you have over 100K followers on any of the following social media platforms?</label>
									<div className="checkbox-group">
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="instagram" /> Instagram
										</label>
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="tiktok" /> TikTok
										</label>
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="youtube" /> YouTube
										</label>
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="twitch" /> Twitch
										</label>
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="snapchat" /> Snapchat
										</label>
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="twitter" /> Twitter
										</label>
										<label>
											<input type="checkbox" name="socialMediaFollowers" value="no" /> No
										</label>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="largestFollowing">If you answered No, what is the largest following you have and which platform is it on?</label>
									<input type="text" id="largestFollowing" name="largestFollowing" />
								</div>
								<div className="form-group">
									<label htmlFor="socialMediaLink">Please provide us with a link to your social media account with the largest following:</label>
									<input type="text" id="socialMediaLink" name="socialMediaLink" />
								</div>
								<div className="form-group">
									<label htmlFor="about">Tell us a bit about yourself. What are your goals? What issues have you had in the past? Why are you currently looking for an agency?</label>
									<textarea id="about" name="about" rows="4"></textarea>
								</div>
								<div className="form-group">
									<label htmlFor="howDidYouHearAboutUs">How did you hear about Eros?</label>
									<input type="text" id="howDidYouHearAboutUs" name="howDidYouHearAboutUs" />
								</div>
							</>
						)}
						{selectedServices.includes('casting') && (
							<>
								<div className="form-group">
									<label htmlFor="castingQuestion">Are you willing to fly to Scottsdale, AZ for filming (for free):</label>
									<div className="radio-group">
										<label>
											<input type="radio" name="castingQuestion" value="yes" required /> Yes
										</label>
										<label>
											<input type="radio" name="castingQuestion" value="no" required /> No
										</label>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="twitterLink">Twitter Link:</label>
									<input type="text" id="twitterLink" name="twitterLink" />
								</div>
								<div className="form-group">
									<label htmlFor="instagramLink">Instagram Link:</label>
									<input type="text" id="instagramLink" name="instagramLink" />
								</div>
								<div className="form-group">
									<label htmlFor="facebookLink">Facebook Link:</label>
									<input type="text" id="facebookLink" name="facebookLink" />
								</div>
								<div className="form-group">
									<label htmlFor="tiktokLink">TikTok Link:</label>
									<input type="text" id="tiktokLink" name="tiktokLink" />
								</div>
							</>
						)}
						<button type="submit">Submit Application</button>
					</form>
					{submitStatus === 'success' && (
						<p className="success-message">Application submitted successfully!</p>
					)}
					{submitStatus === 'error' && (
						<div className="error-message">
							<p>Error submitting the form:</p>
							<pre>{errorMessage}</pre>
						</div>
					)}
				</div>
			</div>
		</section>
	);
}

export default ApplyNow;
