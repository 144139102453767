import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import ImageLeftColumnRight from '../components/ImageLeftColumnRight';
import StartEarning from '../components/StartEarning';
import Payout from '../components/Payout';
import FlyerSection from '../components/FlyerSection'; // Add this line

function Home() {
	return (
		<div className="home">
			<Hero />
			<Features />
			<ImageLeftColumnRight />
			<FlyerSection />
			<StartEarning />
			<FAQ />
			<HowItWorks />
			<Payout />
			<Testimonials />
		</div>
	);
}
export default Home;
