import React from 'react';
import '../styles/features.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faBullhorn, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

function Features() {
	return (
		<section className="features">
			<h2>Our Features</h2>
			<div className="feature-list">
				<div
					className="feature"
					onClick={() => ReactGA.event({ category: 'Features', action: 'Click', label: 'Chatting' })}
				>
					<FontAwesomeIcon icon={faComments} className="icon" />
					<h3>Chatting</h3>
					<p>We provide chatting services to engage with fans on behalf of the models.</p>
				</div>
				<div
					className="feature"
					onClick={() => ReactGA.event({ category: 'Features', action: 'Click', label: 'Marketing' })}
				>
					<FontAwesomeIcon icon={faBullhorn} className="icon" />
					<h3>Marketing</h3>
					<p>Our marketing strategies help models attract more fans and increase their popularity.</p>
				</div>
				<div
					className="feature"
					onClick={() => ReactGA.event({ category: 'Features', action: 'Click', label: 'Casting' })}
				>
					<FontAwesomeIcon icon={faUserCheck} className="icon" />
					<h3>Casting</h3>
					<p>We offer exciting job opportunities for models who want to showcase their talents in scenes.</p>
				</div>
			</div>
		</section>
	);
}

export default Features;
