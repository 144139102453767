// Hero.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/hero.scss';
import modelImage from '../assets/model1.webp';
import ReactGA from 'react-ga';

function Hero() {
	return (
		<section className="hero">
			<div className="container">
				<div className="hero-content">
					<div className="hero-text">
						<h1>More Free Time More Fans</h1>
						<h2>With The Best OnlyFans Agency</h2>
						<p>The Leading OnlyFans Marketing & Management Agency Your One Stop Shop For Success As A Content Creator.</p>
						<Link
							to="/apply-now"
							className="cta-button"
							onClick={() => ReactGA.event({ category: 'Hero', action: 'Click', label: 'Apply Now' })}
						>
							Apply Now
						</Link>
					</div>
					<div className="hero-image">
						<img src={modelImage} alt="Model" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Hero;
