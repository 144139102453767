// Shop.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/shop.scss';
import unlockTipsImage from '../assets/products/unlock_tips_bundle_with_12_pcs_short_sexting_scripts.jpeg';
import unlockTipsFile from '../assets/products/unlock_tips_bundle_with_12_pcs_short_sexting_scripts.docx';
import cockRatesImage from '../assets/products/cock_rates.png';
import cockRatesFile from '../assets/products/cock_rates.docx';
import fullSextingImage from '../assets/products/full_sexting_session_script.jpeg';
import fullSextingFile from '../assets/products/full_sexting_session_script.docx';
import ppvCaptionsImage from '../assets/products/ppv_captions_for_all_types_of_vides_by_chat_angels.jpeg';
import ppvCaptionsFile from '../assets/products/ppv_captions_for_all_types_of_videos_by_chat_angels.docx';
import massDmsImage from '../assets/products/700_mass_dms.jpeg';
import massDmsFile from '../assets/products/700_mass_dms.docx';
import dominatrixImage from '../assets/products/dominatrix_script.jpeg';
import dominatrixFile from '../assets/products/dominatrix_script.pdf';
import allScriptsImage from '../assets/products/all_scripts.jpeg';
import allScriptsFile from '../assets/products/all_scripts.zip';

const testMode = false; // Set to true for testing, false for production
const stripePromise = loadStripe('pk_live_51PTMEpRpQFAW6h1mHKLXiQy7KxYaf8CBN2fCFLpjWvD8eWtx1jolgx0zoXIVpGZqkWB4CXTpF22bZSeXuSfVUZ7600hi6CzUkq');

const products = [
	{
		id: 1,
		name: 'Unlock Tips Bundle with 12 Sexting Scripts',
		description: 'A collection of 12 short sexting scripts to help you unlock tips from your fans.',
		price: 'price_1PTgpaRpQFAW6h1mcFfrk0jf',
		amount: 15.00,
		image: unlockTipsImage,
		file: unlockTipsFile,
	},
	{
		id: 2,
		name: 'Cock Rates Script',
		description: 'A comprehensive script for providing cock ratings to your fans.',
		price: 'price_1PTgpbRpQFAW6h1moFMmWiw4',
		amount: 15.00,
		image: cockRatesImage,
		file: cockRatesFile,
	},
	{
		id: 3,
		name: 'Full Sexting Session Script',
		description: 'A complete script for engaging in full sexting sessions with your fans.',
		price: 'price_1PTgpcRpQFAW6h1mvw7runUd',
		amount: 20.00,
		image: fullSextingImage,
		file: fullSextingFile,
	},
	{
		id: 4,
		name: 'PPV Captions for All Types of Videos',
		description: 'A collection of captions for different types of PPV videos to increase engagement.',
		price: 'price_1PTgpdRpQFAW6h1mv6qQ7IGs',
		amount: 15.00,
		image: ppvCaptionsImage,
		file: ppvCaptionsFile,
	},
	{
		id: 5,
		name: '700 Mass DMs',
		description: 'A set of 700 pre-written mass DMs to send to your fans and increase engagement.',
		price: 'price_1PTgpeRpQFAW6h1m4IkbPVL8',
		amount: 30.00,
		image: massDmsImage,
		file: massDmsFile,
	},
	{
		id: 6,
		name: 'Dominatrix Script',
		description: 'A comprehensive script for roleplaying as a dominatrix with your fans.',
		price: 'price_1PTgpfRpQFAW6h1mZdmlESm8',
		amount: 30.00,
		image: dominatrixImage,
		file: dominatrixFile,
	},
	{
		id: 7,
		name: 'All Scripts Bundle',
		description: 'Get all the above scripts in one convenient bundle at a discounted price.',
		price: 'price_1PTgpgRpQFAW6h1mZ44a8sa8',
		amount: 50.00,
		image: allScriptsImage,
		file: allScriptsFile,
	},
];;

function Shop() {
	const [selectedImage, setSelectedImage] = useState(null);
	const handleBuy = async (product) => {
		if (testMode) {
			// Test mode: Simulate successful payment
			console.log('Test mode: Payment successful');
			// Store the purchased product ID in session storage
			sessionStorage.setItem('purchasedProductId', product.id);
			// Redirect to the success page
			window.location.href = '/success';
		} else {
			// Production mode: Proceed with Stripe Checkout
			const stripe = await stripePromise;
			const { error } = await stripe.redirectToCheckout({
				lineItems: [{ price: product.price, quantity: 1 }],
				mode: 'payment',
				successUrl: `https://eroselitecasting.com/success?productId=${product.id}`,
				cancelUrl: 'https://eroselitecasting.com/shop',
				clientReferenceId: product.id.toString(), // Pass the product ID as clientReferenceId
			});
			if (error) {
				console.error('Error:', error);
			}
		}
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	const handleCloseOverlay = () => {
		setSelectedImage(null);
	};

	return (
		<div className="shop">
			<h1>Shop</h1>
			<div className="product-list">
				{products.map((product) => (
					<div key={product.id} className="product">
						<img
							src={product.image}
							alt={product.name}
							onClick={() => handleImageClick(product.image)}
						/>
						<h2>{product.name}</h2>
						<p>{product.description}</p>
						<p>Price: ${product.amount.toFixed(2)}</p>
						<button className="buy-button" onClick={() => handleBuy(product)}>
							Buy Now
						</button>
					</div>
				))}
			</div>
			{selectedImage && (
				<div className="image-overlay" onClick={handleCloseOverlay}>
					<div className="overlay-content">
						<img src={selectedImage} alt="Selected" />
					</div>
				</div>
			)}
		</div>
	);
}

export default Shop;
export { products }; 
