// About.js
import React from 'react';
import '../styles/about.scss';
import aboutImage from '../assets/about.webp';

function About() {
	return (
		<section className="about">
			<div className="container">
				<h1>About Us</h1>
				<div className="about-content">
					<div className="about-text">
						<p>
							Ero's Elite Casting is a premier casting agency dedicated to connecting top-tier talent with exciting opportunities in the entertainment industry. With our extensive network and industry expertise, we strive to match the perfect talent with the right projects.
						</p>
						<p>
							Our team of experienced casting professionals is passionate about discovering and nurturing exceptional talent. We work closely with models, actors, and performers to help them showcase their unique skills and qualities, and guide them towards successful careers in the industry.
						</p>
					</div>
					<div className="about-image">
						<img src={aboutImage} alt="About Us" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
