import React, { useState } from 'react';
import sendEmail from './api/send-email';
import '../styles/contact.scss';
import ReactGA from 'react-ga';

function Contact() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});
	const [submitStatus, setSubmitStatus] = useState(null);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			await sendEmail(formData, '/api/send-contact-email/');
			console.log('Form submitted successfully');
			setSubmitStatus('success');
			ReactGA.event({
				category: 'Contact Form',
				action: 'Submit',
				label: 'Contact Form Submission',
			});
			// Reset form data or perform any other necessary actions
		} catch (error) {
			console.error('Error submitting form:', error);
			setSubmitStatus('error');
		}
	};

	return (
		<section className="contact">
			<div className="container">
				<h1>Contact Us</h1>
				<div className="contact-content">
					<div className="contact-form">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<input
									type="text"
									id="name"
									name="name"
									placeholder="Your Name"
									value={formData.name}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="form-group">
								<input
									type="email"
									id="email"
									name="email"
									placeholder="Your Email"
									value={formData.email}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="form-group">
								<textarea
									id="message"
									name="message"
									placeholder="Your Message"
									value={formData.message}
									onChange={handleChange}
									required
								></textarea>
							</div>
							<button type="submit">Send Message</button>
						</form>
						{submitStatus === 'success' && (
							<p className="success-message">Message sent successfully!</p>
						)}
						{submitStatus === 'error' && (
							<p className="error-message">An error occurred. Please try again.</p>
						)}
					</div>
					<div className="contact-info">
						<h3>Get in Touch</h3>
						<p>
							If you have any questions, inquiries, or would like to collaborate with us, please don't hesitate to reach out. Our team is always ready to assist you.
						</p>
						<ul>
							<li><i className="fas fa-phone"></i> +1 480 584 1296</li>
							<li><i className="fas fa-envelope"></i> @Camilla_Cream (Telegram)</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contact;
