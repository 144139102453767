import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import HowWeWork from './pages/HowWeWork';
import Contact from './pages/Contact';
import ApplyNow from './pages/ApplyNow';
import Shop from './pages/Shop';
import Success from './pages/Success';
import SuccessTest from './pages/SuccessTest';

function App() {
	return (
		<div className="app">
			<Header />
			<main>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/how-we-work" element={<HowWeWork />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/apply-now" element={<ApplyNow />} />
					<Route path="/shop" element={<Shop />} />
					<Route path="/success" element={<Success />} />
					<Route path="/success-test" element={<SuccessTest />} />
				</Routes>
			</main>
			<Footer />
		</div>
	);
}

export default App;
