// ImageLeftColumnRight.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/imageLeftColumnRight.scss';
import ofEarningsImage from '../assets/ofearnings.png';
import flyerImage from '../assets/flyer_casting.png';
import ReactGA from 'react-ga';

function ImageLeftColumnRight() {
	return (
		<div className="image-left-column-right">
			<div className="container">
				<div className="content">
					<div className="image-column">
						<img src={ofEarningsImage} alt="OnlyFans Earnings" className="image" />
					</div>
					<div className="text-column">
						<img src={flyerImage} alt="Casting Flyer" className="flyer-image" />
						<div className="text-content">
							<h2 className="title">Cutting To The Chase</h2>
							<p className="description">Partner with us to focus on creating content while we manage all other aspects. Our expert teams develop strategies, create accounts, and provide solutions on various platforms. We offer consistent guidance through our management team and your personal dashboard. Your role is simply to create and record content.</p>
							<Link
								to="/apply-now"
								className="cta-button"
								onClick={() => ReactGA.event({ category: 'Image Left Column Right', action: 'Click', label: 'Apply Now' })}
							>
								Apply Now
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageLeftColumnRight;
