// FAQ.js
import React, { useState } from 'react';
import '../styles/faq.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.webp'; // Import your logo image
import ReactGA from 'react-ga';

function FAQ() {
	const faqData = [
		{
			question: 'What Sets Eros Apart From Other OnlyFans Agencies?',
			answer: 'At Eros, Quality Drives Our Actions. Our USA-Based Team Of Dedicated Professionals Deliver Expertise And Top-Notch Strategies That Yield High-Quality Results. We Strive To Turn Your OnlyFans Journey Into A Tale Of Remarkable Success.',
		},
		{
			question: 'Is A Contract Necessary?',
			answer: 'No, we do not require a contract. We believe in building trust and fostering long-term partnerships based on mutual success and satisfaction.',
		},
		{
			question: 'How Can Eros Benefit Me?',
			answer: 'Eros offers a comprehensive suite of services designed to help you maximize your potential on OnlyFans. From account management and tailored marketing strategies to content optimization and audience engagement, we provide the tools and expertise to accelerate your growth and revenue.',
		},
		{
			question: 'Can I Apply If I\'m New To OnlyFans?',
			answer: 'Absolutely! We welcome creators at all stages of their OnlyFans journey. Whether you\'re just starting out or looking to take your existing presence to the next level, our team is here to guide and support you every step of the way.',
		},
		{
			question: 'What Costs Are Involved?',
			answer: 'At Eros, we believe in a fair and transparent partnership. We operate on a revenue-sharing model, which means we only earn when you do. There are no upfront costs or hidden fees. Our success is directly tied to yours, ensuring we are fully committed to helping you thrive on OnlyFans.',
		},
	];

	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [activeQuestion, setActiveQuestion] = useState(null);

	const toggleQuestion = (index) => {
		setActiveQuestion(activeQuestion === index ? null : index);
	};

	return (
		<section className="faq">
			<div className="container">
				<h2 className="faq-title">Frequently Asked Questions</h2>
				<div className="faq-content">
					<div className="faq-list">
						{faqData.map((item, index) => (
							<div
								className={`faq-question ${selectedQuestion === index ? 'active' : ''}`}
								key={index}
								onClick={() => {
									setSelectedQuestion(index);
									ReactGA.event({ category: 'FAQ', action: 'Click', label: `Question ${index + 1}` });
								}}
							>
								{item.question}
							</div>
						))}
					</div>
					<div className="faq-answer-panel">
						{selectedQuestion !== null ? (
							<div className="faq-answer">{faqData[selectedQuestion].answer}</div>
						) : (
							<div
								className="faq-logo"
								onClick={() => ReactGA.event({ category: 'FAQ', action: 'Click', label: 'Logo' })}
							>
								<img src={logo} alt="Logo" />
							</div>
						)}
					</div>
				</div>
				<div className="faq-accordion">
					{faqData.map((item, index) => (
						<div
							className={`faq-item ${activeQuestion === index ? 'active' : ''}`}
							key={index}
						>
							<div className="faq-question" onClick={() => toggleQuestion(index)}>
								<h3>{item.question}</h3>
								<FontAwesomeIcon
									icon={activeQuestion === index ? faChevronUp : faChevronDown}
									className="faq-icon"
								/>
							</div>
							<div className={`faq-answer ${activeQuestion === index ? 'open' : ''}`}>
								<p>{item.answer}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default FAQ;
