// SuccessTest.js
import React from 'react';
import { products } from './Shop';
import '../styles/success.scss';

function SuccessTest() {
	const handleDownload = (productId) => {
		const product = products.find((product) => product.id === productId);

		if (product) {
			const link = document.createElement('a');
			link.href = product.file;
			link.download = product.name;
			link.click();
		}
	};

	return (
		<div className="success-test">
			<h1>Success Test</h1>
			<p>Click the button below to test the file download:</p>
			<button onClick={() => handleDownload(1)}>Download File</button>
		</div>
	);
}

export default SuccessTest;
