// Payout.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/payout.scss';
import payoutImage from '../assets/payout.png';
import ReactGA from 'react-ga';

function Payout() {
	return (
		<div className="payout">
			<div className="container">
				<h2 className="payout-title">
					Unleash your <span className="underline">creativity</span>
				</h2>
				<div className="payout-content">
					<img src={payoutImage} alt="Payout" className="payout-image" />
					<Link
						to="/apply-now"
						className="apply-button"
						onClick={() => ReactGA.event({ category: 'Payout', action: 'Click', label: 'Apply Now' })}
					>
						Apply Now
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Payout;
