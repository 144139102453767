// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.scss';
import logo from '../assets/logo.webp';
import ReactGA from 'react-ga';

function Header() {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<header className="header">
			<div className="container">
				<div className="logo-wrapper">
					<Link to="/" className="logo">
						<img src={logo} alt="Ero's Elite Casting" />
					</Link>
					<div className="title-wrapper">
						<h1 className="title">Ero's Elite Casting</h1>
						<p className="subtitle">For the bold and the beautiful</p>
					</div>
				</div>
				<button
					className={`mobile-menu-toggle ${isMobileMenuOpen ? 'open' : ''}`}
					onClick={toggleMobileMenu}
				>
					<span className="menu-icon"></span>
				</button>
				<nav className={`menu ${isMobileMenuOpen ? 'open' : ''}`}>
					<ul>
						<li>
							<Link to="/" onClick={() => ReactGA.event({ category: 'Header', action: 'Click', label: 'Home' })}>
								Home
							</Link>
						</li>
						<li>
							<Link to="/shop" onClick={() => ReactGA.event({ category: 'Header', action: 'Click', label: 'Shop' })}>
								Shop
							</Link>
						</li>
						<li>
							<Link to="/about" onClick={() => ReactGA.event({ category: 'Header', action: 'Click', label: 'About' })}>
								About
							</Link>
						</li>
						<li>
							<Link to="/contact" onClick={() => ReactGA.event({ category: 'Header', action: 'Click', label: 'Contact' })}>
								Contact
							</Link>
						</li>
					</ul>
					<button
						className="apply-button"
						onClick={() => ReactGA.event({ category: 'Header', action: 'Click', label: 'Apply Now' })}
					>
						<Link to="/apply-now">Apply Now</Link>
					</button>
				</nav>
			</div>
		</header>
	);
}

export default Header;
