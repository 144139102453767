// FlyerSection.js
import React from 'react';
import '../styles/flyerSection.scss';
import chatAngelsVideo from '../assets/chat_angels.mp4';
import camillaVideo from '../assets/camilla_video.mp4';

function FlyerSection() {
	return (
		<section className="flyer-section">
			<div className="container">
				<div className="flyer-content">
					<video className="flyer-video" autoPlay loop muted playsInline>
						<source src={camillaVideo} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<video className="flyer-video" autoPlay loop muted playsInline>
						<source src={chatAngelsVideo} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</section>
	);
}

export default FlyerSection;
