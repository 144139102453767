// Testimonials.js
import React from 'react';
import '../styles/testimonials.scss';
import melodyImage from '../assets/melody.jpeg';
import camillaCreamImage from '../assets/camilla_cream.jpeg';

function Testimonials() {
	const testimonials = [
		{
			name: 'Melody',
			image: melodyImage,
			testimonial: "Ero's Elite Casting has been a game-changer for my career. Their support and guidance have helped me take my content to the next level and connect with a wider audience. I couldn't be happier with my experience!",
		},
		{
			name: 'Camilla Cream',
			image: camillaCreamImage,
			testimonial: "I can't say enough good things about Ero's Elite Casting. They have provided me with invaluable support and advice, and their marketing strategies have helped me attract more fans than ever before. I'm so grateful for their partnership!",
		},
	];

	return (
		<section className="testimonials">
			<div className="container">
				<h2>What Our Clients Say</h2>
				<div className="testimonial-list">
					{testimonials.map((testimonial, index) => (
						<div className="testimonial" key={index}>
							<div className="testimonial-image">
								<img src={testimonial.image} alt={testimonial.name} />
							</div>
							<p>{testimonial.testimonial}</p>
							<p className="author">- {testimonial.name}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Testimonials;
