// StartEarning.js
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/startEarning.scss';
import ReactGA from 'react-ga';

function StartEarning() {
	const containerRef = useRef(null);

	useEffect(() => {
		const container = containerRef.current;
		const rings = container.querySelectorAll('.ring');

		const handleMouseMove = (event) => {
			const { clientX, clientY } = event;
			const { left, top, width, height } = container.getBoundingClientRect();
			const centerX = left + width / 2;
			const centerY = top + height / 2;
			const mouseX = clientX - centerX;
			const mouseY = clientY - centerY;

			rings.forEach((ring, index) => {
				const factor = (index + 1) * 0.05;
				const translateX = mouseX * factor;
				const translateY = mouseY * factor;
				ring.style.transform = `translate(calc(-50% + ${translateX}px), calc(-50% + ${translateY}px))`;
			});
		};

		container.addEventListener('mousemove', handleMouseMove);

		return () => {
			container.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<div className="start-earning" ref={containerRef}>
			<div className="rings">
				<div className="ring ring-1"></div>
				<div className="ring ring-2"></div>
				<div className="ring ring-3"></div>
			</div>
			<h2 className="title">Start Earning Today</h2>
			<p className="description">Get In Touch To Learn How Our Strategies And Solutions Can Increase Fan Count And Revenue For Your OnlyFans Page</p>
			<Link
				to="/apply-now"
				className="cta-button"
				onClick={() => ReactGA.event({ category: 'Start Earning', action: 'Click', label: 'Apply Now' })}
			>
				Apply Now
			</Link>
		</div>
	);
}

export default StartEarning;
