// HowItWorks.js
import React from 'react';
import '../styles/howItWorks.scss';

function HowItWorks() {
	return (
		<section className="how-it-works">
			<div className="container">
				<h2>How It Works</h2>
				<div className="steps">
					<div className="step">
						<div className="step-number">1</div>
						<h3>Submit your project details</h3>
						<p>Tell us about your project requirements and preferences.</p>
					</div>
					<div className="step">
						<div className="step-number">2</div>
						<h3>We match you with the best talent</h3>
						<p>Our experts handpick the most suitable talent for your project.</p>
					</div>
					<div className="step">
						<div className="step-number">3</div>
						<h3>Connect and collaborate</h3>
						<p>We facilitate seamless communication and collaboration between you and the talent.</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HowItWorks;
